import * as React from "react";
import { useState } from "react";
import {
  motion,
  AnimatePresence,
  useInView,
  useAnimation,
} from "framer-motion";
import { wrap } from "popmotion";
import { getImage } from "gatsby-plugin-image";
import { CarruselArrowRight } from "../../images/icons";
import { LinkIcon, LinkType, VrLink } from "../shared/links";
import { Link } from "gatsby";
import slugify from "slugify";
import RotatingImg from "../projects/rotatingImg";

export const CarouselProject = ({ dataImages, height, sereis }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, dataImages.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  const theImages = getImage(
    dataImages[imageIndex] &&
      dataImages[imageIndex].heroImage.imageFile.localFile.childImageSharp
        .gatsbyImageData
  );
  // Animation inView
  // IN-VIEW SHOW
  const pageContainerRef = React.useRef(null);
  const isInView = useInView(pageContainerRef, { once: false, amount: 0.1 });
  const inViewControls = useAnimation();
  const inViewVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 5,
    },
  };
  React.useEffect(() => {
    if (isInView) {
      inViewControls.start("visible");
    } else if (!isInView) {
      inViewControls.start("hidden");
    }
  }, [inViewControls, isInView]);

  return (
    <div id={"carousel container"} className={"mt-16"}>
      {dataImages[imageIndex] ? (
        <motion.div
          id={"carousel motion container"}
          ref={pageContainerRef}
          animate={inViewControls}
          initial={"hidden"}
          variants={inViewVariants}
          transition={{ type: "easeOut", duration: 1 }}
          className={"w-full"}
          // className={`w-full`}
        >
          <AnimatePresence
            initial={false}
            custom={direction}
            mode={"popLayout"}
          >
            <motion.div
              id={"motion div"}
              className="w-full"
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: {
                  type: "spring",
                  stiffness: 300,
                  damping: 50,
                  duration: 0.2,
                },
                opacity: { duration: 0.5 },
              }}
            >
              <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-16">
                {/* INFORMATION */}

                <div className="order-last md:order-first" id={"texts"}>
                  {/* Type */}
                  <LinkType
                    slug={"project-type"}
                    type={dataImages[imageIndex].projectType.projectType}
                    isMedium={false}
                  />

                  {/* Title */}
                  <div className="mt-8 text-lg">
                    <LinkIcon> {dataImages[imageIndex].title}</LinkIcon>
                  </div>
                  {/* Year */}
                  <div className="mt-8">
                    {" "}
                    <LinkIcon>{dataImages[imageIndex].year}</LinkIcon>{" "}
                  </div>
                  {/* Text */}
                  {dataImages[imageIndex].contentType[0].strapi_component ===
                  "exhibition.other" ? (
                    <LinkIcon classes={"mt-8"}>
                      <Link
                        to={`/project/${slugify(
                          dataImages[imageIndex].title.toLowerCase()
                        )}`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              dataImages[
                                imageIndex
                              ].contentType[0].textWYSIWYG.data.textWYSIWYG.substring(
                                0,
                                200
                              ) + "...",
                          }}
                        />
                      </Link>
                    </LinkIcon>
                  ) : (
                    dataImages[imageIndex].contentType[0].strapi_component ===
                      "exhibition.exhibition" && (
                      <LinkIcon classes={"mt-8"}>
                        <Link
                          to={`/project/${slugify(
                            dataImages[imageIndex].title.toLowerCase()
                          )}`}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                dataImages[
                                  imageIndex
                                ].contentType[0].text.data.text.substring(
                                  0,
                                  200
                                ) + "...",
                            }}
                          />
                        </Link>
                      </LinkIcon>
                    )
                  )}
                </div>

                {/* /* IMAGES */}
                <div className="order-first md:order-last relative">
                  <Link
                    className="relative"
                    to={`/project/${slugify(
                      dataImages[imageIndex].title.toLowerCase()
                    )}`}
                  >
                    <div className="flex justify-center items-center">
                      <RotatingImg
                        index={imageIndex}
                        imageA={theImages}
                        imageB={theImages}
                        classes={
                          "xs:max-w-[300px] md:max-w-[400px] md:h-[400px] xs:h-[300px] xs:w-[60%] lg:w-[60%]"
                        }
                      />
                    </div>
                    {/* ------ VR LINK? ------  */}
                    {/* {dataImages[imageIndex].contentType[0].strapi_component ===
                  "exhibition.exhibition" ? (
                    <div className="absolute top-0 w-full h-full flex justify-center items-center">
                      <VrLink url={"http://www.cnn.com"} />
                    </div>
                  ) : null} */}
                  </Link>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          {dataImages.length > 1 ? (
            <div
              id={"carousel arrows container"}
              className="w-full h-full flex justify-between items-center z-[2] mt-8"
            >
              <LinkIcon classes={""}>
                <button
                  onClick={() => paginate(1)}
                  className={" p-1  w-[60px]"}
                >
                  <CarruselArrowRight classes={"w-full scale-[-1]"} />
                </button>
              </LinkIcon>
              <LinkIcon classes={""}>
                <button className=" p-1  w-[60px]" onClick={() => paginate(-1)}>
                  <CarruselArrowRight classes={"w-full"} />
                </button>
              </LinkIcon>
            </div>
          ) : null}
        </motion.div>
      ) : null}
    </div>
  );
};
// FRAMER MOTION VARIANT
const x = 1000;
const variants = {
  enter: (direction) => {
    return {
      opacity: 0,
      x: direction > 0 ? x : -x,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      x: direction < 0 ? x : -x,
      opacity: 0,
    };
  },
};
