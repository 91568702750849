import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import { LinkIcon } from "../components/shared/links";
import { ArrowDownAccordion, ArrowRegIcon } from "../images/icons";
import { CarouselProject } from "../components/home/carouselProject";
import { WorkGridItem } from "../components/shared/workGridItem";
import { gridStyle } from "../utils/gridStyles";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useInView,
} from "framer-motion";
import { LogoBottom, LogoTop } from "../images/logos";
import { LoaderScroll } from "../components/home/loaderScroll";
import { LoaderIconScroll } from "../components/home/loaderIconScroll";
import { LoderLineScroll } from "../components/home/loaderLineScroll";
import { colors, stickyNav } from "../components/shared/styles";

const IndexPage = (props) => {
  // ---> Get all Data <--- //
  const projects = props.data.allStrapiProject.nodes;
  const works = props.data.allStrapiWork.nodes;
  // Filtered state
  // const [featuredWorks, setFeaturedWorks] = React.useState([]);
  // Filter only 6
  // const featuredProjects = projects
  //   .filter((project, index) => project.featured === true)
  //   .slice(0, 3);
  // const featuredWorks = works
  //   .filter((work, index) => work.featured === true)
  //   .slice(0, 6);
  // console.log("reg: ", featuredWorks);
  // LOGO
  const logoRef = React.useRef(null);
  const pageContentRef = React.useRef(null);

  const { scrollY } = useScroll({
    ref: logoRef,
    offset: ["end end", "start start"],
  });

  // Scroll
  const scrollYTransformTop = useTransform(scrollY, [0, 300], [0, -100]);
  const scrollYTransformBottom = useTransform(scrollY, [0, 100], [0, -100]);
  const opacityTransformBg = useTransform(scrollY, [0, 400], [1, 0]);
  const opacityTransformNavbar = useTransform(scrollY, [0, 400], [0, 1]);
  const yTransformNavbar = useTransform(scrollY, [0, 400], [-72, 0]);

  const spring = {
    stiffness: 1000,
    damping: 200,
    restDelta: 0.2,
    duration: 1,
  };
  const navSpring = {
    stiffness: 2000,
    damping: 200,
    restDelta: 0.2,
    duration: 0.5,
  };

  const yTop = useSpring(scrollYTransformTop, spring);
  const yBottom = useSpring(scrollYTransformBottom, spring);
  const bgOpacity = useSpring(opacityTransformBg, spring);
  const navbarOpacity = useSpring(opacityTransformNavbar, spring);
  const navbarYTransfrom = useSpring(yTransformNavbar, navSpring);

  // Button scrol to...
  const executeScroll = () =>
    pageContentRef.current.getBoundingClientRect().y > 0
      ? pageContentRef.current.scrollIntoView({ behavior: "smooth" })
      : null;

  // const checkSticky = () =>
  //   pageContentRef.current.getBoundingClientRect().y >= 580
  //     ? console.log(pageContentRef.current.getBoundingClientRect())
  //     : null;

  // Check if scrolled once?
  const [scrolled, setScrolled] = React.useState(false);

  // Inview
  const isInView = useInView(pageContentRef, {
    margin: "-120px 0px 0px 0px",
  });

  // Random Color per session
  const [color, setColor] = React.useState(false);
  React.useEffect(() => {
    setColor(colors[Math.floor(Math.random() * colors.length)]);
  }, []);

  // test
  const [isVisible, setIsVisible] = React.useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (logoRef.current) observer.observe(logoRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
    };
  }, [logoRef, options]);

  // featured work test
  // React.useEffect(() => {
  //   setFeaturedWorks(
  //     works.filter((work, index) => work.featured === true).slice(0, 6)
  //   );
  //   console.log("useEffect: ", featuredWorks);
  // }, []);
  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
      navbarHeight={true}
      navbarOpacity={navbarOpacity}
      navbarYTransfrom={navbarYTransfrom}
    >
      {/* Backround color */}
      <motion.div
        className={`fixed top-0 left-0 ${color} h-screen w-full z-[2] pointer-events-none`}
        style={{ opacity: bgOpacity }}
      />

      {/* LOGO */}
      <div
        className={`relative h-screen w-full z-[2] flex justify-center items-center`}
        ref={logoRef}
        onClick={() => executeScroll()}
        role={"button"}
      >
        {/* Arrow */}
        <motion.div
          className={`absolute 
           flex justify-center items-center bottom-xl`}
          style={{ opacity: bgOpacity }}
        >
          <div className="flex items-center ">
            {scrolled === false ? (
              <LoaderIconScroll
                classes={"col-span-full h-[2.5rem]"}
                color="black"
                executeScroll={executeScroll}
                setScrolled={setScrolled}
              />
            ) : (
              <ArrowDownAccordion classes={"h-[0.75rem] mr-sm"} />
            )}
            <div className="text-sm">Scroll down...</div>
          </div>
        </motion.div>

        <div className="h-full">
          <motion.div
            className="sticky top-1/4"
            style={{
              y: yTop,
              opacity: isInView ? 1 : 0,
              transition: "opacity 0.5s ease-out",
            }}
          >
            <LogoTop classes={`w-screen max-w-full`} />
          </motion.div>
          <motion.div
            className="sticky top-2/4"
            style={{
              y: yBottom,
              opacity: isInView ? 1 : 0,
              transition: "opacity 0.5s ease-out",
            }}
          >
            <LogoBottom classes={`w-screen max-w-full`} />
          </motion.div>
        </div>
      </div>
      {/* ----- Artists Grid Container----- */}
      <div
        ref={pageContentRef}
        className={`xs:scroll-mt-[7.3rem] md:scroll-mt-[7.5rem]`}
      >
        <div
          className={`${stickyNav} z-[3] ${
            isVisible ? "bg-transparent" : "bg-yellow-50"
          } `}
        >
          Recent acquisitions:
        </div>

        <div className={`${gridStyle} mt-xl`}>
          {/* Start> ----- Artists Work Item Template ----- */}
          {works.map((work, index) => {
            // console.log(
            //   index <= 5 && work.featured === true ? work.title : null
            // );
            return (
              // index <= 5 && work.featured === true ?
              <WorkGridItem
                key={index}
                work={work}
                classes={``}
                isMedium={true}
                location={props.location}
              />
            );
            // : null;
          })}
          {/* END > ----- Artists Work Item Template ----- */}
        </div>

        {/* SEE ALL COLLECTION */}
        <Link to={`/artists/works`}>
          <div className="mt-xxl flex justify-center items-center">
            <ArrowRegIcon classes={`h-[1rem] opacity-50 mr-sm`} />
            <div>See the entire collection</div>
          </div>
        </Link>
      </div>
      {/* Projects Grid */}
      {projects != 0 ? (
        <div className="preimeryBg py-xl mt-xl -mx-base sm:-mx-md  md:-mx-md lg:-mx-lg px-base sm:px-md md:px-md lg:px-lg">
          {/* <div className="text-base">Recent Projects:</div> */}
          <div className={`${stickyNav} bg-yellow-50`}>Recent Projects:</div>
          <CarouselProject
            dataImages={projects}
            height={"h-[33vh] md:h-[33vh] lg:h-[50vh]"}
          />
          {/* /* SEE ALL COLLECTION */}
          <div className="flex justify-center">
            <LinkIcon classes={"mt-xl"}>
              <Link
                to={`/projects`}
                className={"w-full flex justify-center items-center"}
              >
                <ArrowRegIcon classes={`h-[1rem] w-auto opacity-50 mr-sm `} />
                <div>See All Projects</div>
              </Link>
            </LinkIcon>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};
export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiProject(
      filter: {
        featured: { eq: true }
        title: { regex: "/^(?!.*?DO NOT DELETE).*/g" }
      }
      limit: 3
      sort: { order: ASC, fields: year }
    ) {
      nodes {
        featured
        title
        year
        projectType {
          projectType
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  width: 700
                  #height: 100
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        contentType {
          ... on STRAPI__COMPONENT_EXHIBITION_OTHER {
            strapi_component
            textWYSIWYG {
              data {
                textWYSIWYG
              }
            }
          }
          ... on STRAPI__COMPONENT_EXHIBITION_EXHIBITION {
            strapi_component
            curatorName {
              firstName
              lastName
            }
            text {
              data {
                text
              }
            }
          }
        }
      }
    }
    allStrapiWork(
      filter: { featured: { eq: true } }
      limit: 6
      sort: { order: ASC, fields: artist___lastName }
    ) {
      nodes {
        collection {
          collectionName
        }
        featured
        mediums {
          mediumType
        }
        title
        year
        artist {
          firstName
          lastName
          slug
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  width: 700
                  #height: 100
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Home"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
