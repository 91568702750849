import React from "react";
import { motion, useAnimate, useMotionValue } from "framer-motion";

export const LoaderIconScroll = ({
  classes,
  color,
  executeScroll,
  setScrolled,
}) => {
  const [scope, animate] = useAnimate();
  const pathLength = useMotionValue(0);

  React.useEffect(() => {
    const controls = animate(pathLength, 1, {
      duration: 5,
      onComplete: () => {
        executeScroll();
        setScrolled(true);
      },
    });
    return controls.stop;
  }, []);

  return (
    <svg
      ref={scope}
      className={`${classes}`}
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <motion.g
        transform="translate(-10)"
        animate={{ y: [3, -3, 3] }}
        transition={{ duration: 1, times: [0, 0.7, 1], repeat: Infinity }}
      >
        <motion.line
          y2="25"
          transform="translate(40 52) rotate(180)"
          strokeWidth="2"
          pathLength="1"
          stroke={color}
          fill="none"
        />
        <motion.path
          d="M.449,5.135l4.5-4.5,4.5,4.5"
          transform="translate(44.949 52.635) rotate(180)"
          strokeWidth="2"
          pathLength="1"
          stroke={color}
          fill="none"
        />
      </motion.g>

      <motion.rect
        width="28"
        height="50"
        rx="14"
        transform="translate(26 15)"
        strokeWidth="2"
        pathLength="1"
        stroke={color}
        fill="none"
      />
    </svg>
  );
};
